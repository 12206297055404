import React from "react";
import "./Footer.css";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-links">
        <div className="footer-subscribe">
          <p>Subscribe to be the first to know <br /> about our latest courses</p>

          <div class="footer-subscribe-input">
            <input type="email" placeholder="Email address" />
            <button>→</button>
          </div>
          <div class="footer-social">
            <div class="facebook">
              <a href="https://www.facebook.com/profile.php?id=61561028583467&mibextid=ZbWKwL" aria-label="Facebook">
                <i class="fab fa-facebook"></i>
              </a>
            </div>
            <div class="twitter">
              <a href="https://www.linkedin.com/in/accent-aura-ba1965314?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app" aria-label="Twitter">
                <i class="fab fa-linkedin"></i>
              </a>
            </div>
            <div class="instagram">
              <a href="https://www.instagram.com/accent_aura?igsh=MXhrem1wZjIwaTloeg==" aria-label="Instagram">
                <i class="fab fa-instagram"></i>
              </a>
            </div>
          </div>
        </div>
        <div className="footer-column">
          <a href="#/maintainance">Cancellation Policy</a>
          <a href="/#">Quick Links</a>
          <a href="#/maintainance">Site map</a>
          <a href="#/maintainance">FAQs</a>
          <a href="/contact">Contact Us</a>
        </div>
        <div className="footer-column">
          <a href="#/about">About Us</a>
          <a href="#/maintainance">Our Process</a>
          <a href="#/maintainance">Affiliate</a>
          <a href="#/career">Teach with Us</a>
          <a href="#/maintainance">Accent Aura Trainee</a>
        </div>
        <div className="footer-column1">
          <p>Language</p>
          <div className="English">
            <select>
              <option value="en">English</option>
            </select>
          </div>
        </div>
      </div>

      <div className="footer-bottom">
        <p>© 2024 Accent Aura</p>
        <div className="footer-legal">
          <a href="#privacy">Privacy Policy</a>
          <a href="#terms">Terms & Conditions</a>
          <a href="#accessibility">Accessibility</a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
